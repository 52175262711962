import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import { Auth } from "./components/Admin/components/Auth";
import { CouponPage } from "./components/Admin/components/Coupon";
import { SamStudio } from "./components/Admin/components/SamStudio";
import { AddSamStudio } from "./components/Admin/components/SamStudio/addEditStudio";
import { SamTheme } from "./components/Admin/components/SamTheme";
import { AddSamTheme } from "./components/Admin/components/SamTheme/addEditTheme";
import { SamWatch } from "./components/Admin/components/SamWatch";
import { YamWatchAdmin } from "./components/Admin/components/YamWatch";
import { AddSamWatch } from "./components/Admin/components/SamWatch/addEditWatch";
import { FSamStudio } from "./components/App/components/SamStudio";
import { FSamTheme } from "./components/App/components/SamTheme";
import { FSamWatch } from "./components/App/components/SamWatch";
import { FYamWatch } from "./components/App/components/YamWatch";
import { ToastContainer } from "react-toastify";
import { ViewSamWatch } from "./components/Admin/components/SamWatch/view";
import { ViewSamTheme } from "./components/Admin/components/SamTheme/view";
import { ViewSamStudio } from "./components/Admin/components/SamStudio/view";
import { App } from "./components/App/App";
import { SamAbout } from "./components/App/components/SamAbout";
import RouteChangeTracker from "./components/RouteChangeTracker";
import IconGenerator from "./components/Admin/components/IconGenerator";
import { AddYamWatch } from "./components/Admin/components/YamWatch/addEditYam";
import { ViewYamWatch } from "./components/Admin/components/YamWatch/view";
import { FloatingMenu } from "./components/Admin/components/FloatingMenu/floatingMenu";

export const Main = () => {
  RouteChangeTracker();
  return (
    <div className="h-full w-full">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={false}
        pauseOnHover
      />
      <Routes>
        <Route path="/login" element={<Auth />} />

        <Route path="/dashboard" element={<SamWatch />} />
        <Route path="/samwatch" element={<FSamWatch />} />
        <Route path="/yamwatch" element={<FYamWatch />} />
        <Route path="/samtheme" element={<FSamTheme />} />
        <Route path="/samstudio" element={<FSamStudio />} />
        <Route path="/samabout" element={<SamAbout />} />

        {/* 
          FIXME -
          똑같은 코드가 왜이렇게 많은지 이해가 안감 
          추후 개선 시 중복코드 모두 덜어내고 모듈화로 변경
          커플링을 최소화 해야함
        */}
        <Route path="/dashboard/samwatch" element={<SamWatch />} />
        <Route path="/dashboard/samwatch/register" element={<AddSamWatch />} />
        <Route path="/dashboard/samwatch/:id" element={<ViewSamWatch />} />
        <Route path="/dashboard/samwatch/edit/:id" element={<AddSamWatch />} />

        <Route path="/dashboard/yamwatch" element={<YamWatchAdmin />} />
        <Route path="/dashboard/yamwatch/register" element={<AddYamWatch />} />
        <Route path="/dashboard/yamwatch/:id" element={<ViewYamWatch/>} />
        <Route path="/dashboard/yamwatch/edit/:id" element={<AddYamWatch />} />

        <Route path="/dashboard/samtheme" element={<SamTheme />} />
        <Route path="/dashboard/samtheme/register" element={<AddSamTheme />} />
        <Route path="/dashboard/samtheme/:id" element={<ViewSamTheme />} />
        <Route path="/dashboard/samtheme/edit/:id" element={<AddSamTheme />} />

        <Route path="/dashboard/samstudio" element={<SamStudio />} />
        <Route path="/dashboard/samstudio/register" element={<AddSamStudio />} />
        <Route path="/dashboard/samstudio/:id" element={<ViewSamStudio />} />
        <Route
          path="/dashboard/samstudio/edit/:id"
          element={<AddSamStudio />}
        />
        <Route path="/dashboard/coupon" element={<CouponPage />} />
        {/* <Route path="/dashboard/banner" element={<BannerPage />} /> */}
        <Route path="/dashboard/floating-menu" element={<FloatingMenu />} />
        <Route path="/iconGen" element={ <IconGenerator/> } />
        <Route path="*" element={<App />} />
      </Routes>
    </div>
  );
};
