import { Box, CircularProgress } from "@mui/material"
import { useState, useEffect } from "react"

interface CircularIndicatorProps {
    color: string[]
    show: boolean
}

export const CircularIndicator = (props: CircularIndicatorProps) => {
    const [size, setSize] = useState<number>(0)

    useEffect(() => {
        const updateSize = () => {
            const newSize = Math.min(window.innerWidth, window.innerHeight) * 0.1
            setSize(newSize)
        };
        updateSize()
        window.addEventListener("resize", updateSize)
        return () => window.removeEventListener("resize", updateSize)
    },[])
    

    if(props.show) {
        return (
            <Box sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 배경
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000 
            }}>
                    <CircularProgress 
                        size={size} 
                        thickness={1}
                        sx={{ 
                            "color": props.color[0]
                        }} 
                    />
            </Box>
        )
    }

    return(
        <></>
    ) 
}